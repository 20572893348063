export function preventdoublemail($) {
  $(document).ready(function() {
    $('.easyformForm').submit(function() {
      var button = $('#form-buttons-submit');
      button.removeAttr('clicked');
      if (button.hasClass('submitting')) return false;
      button.addClass('submitting');
    });
  });
}
