export function product($) {
  $('.template-product_page .product-anchor a').click(function(e) {
    e.preventDefault();
    const scrollTop = $(e.currentTarget.getAttribute('href')).offset().top;
    $('body,html').animate({ scrollTop }, 500);
  });

  $('.other-products-collection .more-products button').click(function() {
    $('.other-products-collection .tile-content ul.collapsed').removeClass(
      'collapsed',
    );
  });
}
