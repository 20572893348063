import $ from 'jquery';

import './styles.scss';

import { affix } from './scripts/affix';
import { fa } from './scripts/fa';
import { returntotop } from './scripts/returntotop';
import { tiles } from './scripts/tiles';
import { search } from './scripts/search';
import { mobilemenu } from './scripts/mobilemenu';
import { headeraffix } from './scripts/headeraffix';
import { faqfilter } from './scripts/faqfilter';
import { product } from './scripts/product';
import { footersvg } from './scripts/footersvg';
import { preventdoublemail } from './scripts/preventdoublemail';

$(function() {
  const options = {
    screen_sm_min: 768,
    screen_sm_max: 767,
  };

  fa();
  affix($);
  returntotop($);
  tiles($, options.screen_sm_min);
  search($);
  mobilemenu($);
  headeraffix($);
  faqfilter($);
  footersvg($);
  product($);
  preventdoublemail($);
});

if (process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}
