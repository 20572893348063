export function mobilemenu($) {
  function openMobileMenu() {
    $('#portal-header').addClass('open');
    $('body').addClass('menu-open');
    $('#language-wrapper').on('shown.bs.dropdown', function() {
      const div = $('#portal-languageselector');
      $('#portal-header').scrollTop(div.prop('scrollHeight') + 50);
    });
  }

  function closeMobileMenu() {
    $('#portal-header').removeClass('open');
    $('body').removeClass('menu-open');
  }

  $('#mobile-header button.mobile-menu-toggle').click(function() {
    if (!$('#portal-header').hasClass('open')) {
      openMobileMenu();
    }
  });

  $('#portal-header button.close-menu-button').click(function() {
    if ($('#portal-header').hasClass('open')) {
      closeMobileMenu();
    }
  });

  // gestione click out per chiudere menu
  $(document).click(function(e) {
    if (
      !$(e.target).closest('#portal-header').length &&
      $('#portal-header').hasClass('open') &&
      !$(e.target).closest('button.mobile-menu-toggle').length
    ) {
      closeMobileMenu();
    }
  });
}
