export function footersvg($) {
  $('.footer-logo-info p:first-of-type a').append(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 539.33 90.04" style="width: 100%;">
      <defs>
        <style>
          .footer-logo-info .cls-1{fill:#fff}.cls-4{fill:#e2001a}
        </style>
      </defs>
      <g id="Livello_1" data-name="Livello 1">
        <path class="cls-1" d="M301.92 64.06v7.31a3.83 3.83 0 0 1-3.83 3.83h-6.52a3.83 3.83 0 0 1-3.83-3.83V34.44a3.83 3.83 0 0 1 3.83-3.83h6.52a3.83 3.83 0 0 1 3.83 3.83v7.15h14.36V29.15a13.41 13.41 0 0 0-13.41-13.4h-16.09a13.41 13.41 0 0 0-13.41 13.4v47.51a13.41 13.41 0 0 0 13.41 13.41h16.08a13.41 13.41 0 0 0 13.41-13.41v-12.6z" transform="translate(-11.24 -13.21)"/>
        <path d="M495.51 73l-.22.13" transform="translate(-11.24 -13.21)" fill="#fff"/>
        <path d="M484.27 59.79l-.22.13" fill="none" stroke="#000" stroke-miterlimit="3.86" stroke-width=".17"/>
        <path class="cls-1" d="M459 90.08V15.74h29.48a13.41 13.41 0 0 1 13.41 13.4V61.6a13.53 13.53 0 0 1-6.51 11.4l6.45 17H487.3l-13.93-32.56h10.36a3.83 3.83 0 0 0 3.83-3.83V34.44a3.83 3.83 0 0 0-3.83-3.83h-10.36v59.5z" transform="translate(-11.24 -13.21)"/>
        <path class="cls-1" d="M324.43 62.49h24.02v14.36h-38.51V2.53h38.51v14.36h-24.16v15.37l19.6.17v14.35h-19.6l.14 15.71zM514.91 18.73l-9.88 58.12h-14.35l12.63-74.32H526.69l12.64 74.32h-14.36l-9.94-58.45-.12.33z"/>
        <path class="cls-1" d="M515 61.99h-7.43L515 76.86l7.43-14.87H515z"/>
        <path class="cls-1" d="M364.27 15.82h29.48a13.41 13.41 0 0 1 13.41 13.4v14.4a13.17 13.17 0 0 1-3.89 9.18 13.36 13.36 0 0 1 3.89 9.43v14.42a13.41 13.41 0 0 1-13.41 13.41h-29.49zM389 30.69h-10.36v15H389a3.83 3.83 0 0 0 3.83-3.83v-7.34a3.83 3.83 0 0 0-3.83-3.83m0 29.65h-10.36v15H389a3.82 3.82 0 0 0 3.83-3.83v-7.35a3.82 3.82 0 0 0-3.83-3.83M411.42 29.24A13.55 13.55 0 0 1 425 15.69h16a13.55 13.55 0 0 1 13.56 13.55v47.28A13.55 13.55 0 0 1 441 90.08h-16a13.55 13.55 0 0 1-13.55-13.56zm14.46 5.23V71.3a3.88 3.88 0 0 0 3.87 3.87h6.44a3.87 3.87 0 0 0 3.87-3.87V34.47a3.87 3.87 0 0 0-3.87-3.87h-6.44a3.88 3.88 0 0 0-3.87 3.87" transform="translate(-11.24 -13.21)"/>
        <path class="cls-4" d="M266.28 60l-39.19-3.55 4.27 9.55-103.59-9 4.94 11-104.16-9-.09-.22 8.52 20.39 98 8.78-4.54-11.07 103.13 9-4.09-9.62 36.81 3.21zM266.28 24.36l-65.71-5.74 4.27 9.54-103.59-9 4.94 11-92.82-8.22-.11-.25 8.53 20.42 86.68 7.92L103.91 39 207 48l-4.09-9.63 63.33 5.41z" transform="translate(-11.24 -13.21)"/>
        <path class="cls-1" d="M29 80.29V79h1.12c.45 0 1.11 0 1.11.61s-.36.71-.87.69zm1.87.69c.82-.05 1.25-.4 1.25-1.25a1.32 1.32 0 0 0-.54-1.2 2.65 2.65 0 0 0-1.25-.21h-2.27v4.61H29V81h.91l1 1.95h1zm-1 3.1a3.47 3.47 0 1 1 3.43-3.48 3.46 3.46 0 0 1-3.47 3.48m0 .81a4.28 4.28 0 1 0-4.28-4.28 4.29 4.29 0 0 0 4.28 4.28" transform="translate(-11.24 -13.21)"/>
      </g>
      <g id="Livello_2" data-name="Livello 2">
        <path class="cls-1" d="M15.19 40.71l2.13 5 104.16 9.04-4.95-11.01 103.59 9.04-4.26-9.54 39.18 3.55v-4.9l-46.33-4.05 4.1 9.62-103.71-9.04 4.73 11.07-98.64-8.78zM25.66 65.9l2.13 5 103.2 9.05-4.37-11.01 103.02 8.84-3.7-9.35 29.1 2.51v-4.71l-36.81-3.2 4.1 9.62-103.14-9.04 4.54 11.07-98.07-8.78zM0 3.73l2.13 5 92.83 8.19-4.95-11.01 103.59 9.03-4.26-9.54 65.7 5.75V6.24L182.19 0l4.1 9.62L82.58.58l4.73 11.07L0 3.73z"/>
        <path class="cls-1" d="M10.47 28.92l2.14 5 91.86 8.19-4.37-11.01 103.02 8.84-3.7-9.34 55.62 4.7v-4.7l-63.33-5.41 4.1 9.62-103.14-9.04 4.54 11.07-86.74-7.92z"/>
      </g>
    </svg>
  `);
}
