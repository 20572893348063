import 'slick-carousel';

export function tiles($, sliderMinWidth) {
  if (!$.fn.slick) {
    // eslint-disable-next-line no-console
    console.error('Slick is not available.');
  } else {
    $('.weldingproducts.tile .tileContent').slick({
      dots: true,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: sliderMinWidth - 1,
          settings: 'unslick',
        },
      ],
      prevArrow:
        '<button class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
      nextArrow:
        '<button class="slick-next"><i class="fas fa-chevron-right"></i></button>',
    });
  }

  if (
    $('.tile.slider').length > 0 &&
    $('.tile.slider .slider-image').length > 1
  ) {
    $('.tile.slider .slider-content').slick({
      dots: false,
      arrows: false,
      autoplay: true,
    });
  }

  // Welding support
  $('.weldingsupport .welding-support-text li > a').prepend(
    '<i class="fas fa-arrow-circle-right"></i>',
  );

  // Welding solutions, news, events
  $('.weldingsolutions, .weldingnews, .weldingevents, .weldingvideos')
    .find('.tileHeader h2')
    .click(function() {
      if ($(window).width() < sliderMinWidth) {
        $(this)
          .closest('.tileHeader')
          .toggleClass('open');
        $(this)
          .closest('.tileContent')
          .find('.items')
          .slideToggle();
      }
    });
}
