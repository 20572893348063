export function search($) {
  function openSearchPanel() {
    $('.header-search .search-button').css('visibility', 'hidden');
    $('#portal-searchbox').addClass('open');
  }

  function closeSearchPanel() {
    $('#portal-searchbox').removeClass('open');
    setTimeout(function() {
      $('.header-search .search-button').css('visibility', '');
    }, 500);
  }

  $('.header-search .search-button').click(function() {
    if (!$('#portal-searchbox').hasClass('open')) {
      openSearchPanel();
      $('#portal-searchbox #searchGadget').focus();
    }
  });

  $('#portal-searchbox .close-button').click(function() {
    if ($('#portal-searchbox').hasClass('open')) {
      closeSearchPanel();
    }
  });

  // gestione click out per chiudere searchbox
  $(document).click(function(e) {
    if (
      !$(e.target).closest('#portal-searchbox').length &&
      $('#portal-searchbox').hasClass('open') &&
      !$(e.target).closest('button.search-button').length
    ) {
      closeSearchPanel();
    }
  });
}
