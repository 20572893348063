import { library, dom } from '@fortawesome/fontawesome-svg-core';

import {
  faMapMarkerAlt as faMapMarkerAltS,
  faPencil,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faArrowCircleRight,
  faLongArrowRight as faLongArrowRightSolid,
  faArrowToBottom,
  faFilter,
  faUser,
  faEllipsisH,
  faPhone,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faChevronDown,
  faAngleDown as faAngleDownR,
  faBars,
  faTimes as faTimesR,
  faLongArrowRight as faLongArrowRightReg,
  faArrowCircleLeft as faArrowCircleLeftR,
  faArrowCircleRight as faArrowCircleRightR,
  faPencilAlt,
  faMapMarkerAlt as faMapMarkerAltR,
  faCalendarAlt,
  faTrashAlt,
  faEnvelope,
  faFilePdf,
  faFileDownload,
  faLongArrowDown,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faAngleDown as faAngleDownL,
  faAngleRight,
  faGlobe,
  faSearch,
  faTimes as faTimesL,
  faArrowCircleLeft as faArrowCircleLeftL,
  faArrowCircleRight as faArrowCircleRightL,
  faPlayCircle,
  faTachometer,
  faHandHoldingHeart,
  faBatteryBolt,
  faUsersCog,
} from '@fortawesome/pro-light-svg-icons';

import {
  faYoutube,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

export function fa() {
  library.add(
    faMapMarkerAltS,
    faChevronDown,
    faAngleDownL,
    faAngleDownR,
    faAngleRight,
    faGlobe,
    faYoutube,
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faPencil,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faSearch,
    faBars,
    faTimesL,
    faTimesR,
    faArrowCircleRight,
    faLongArrowRightReg,
    faLongArrowRightSolid,
    faArrowCircleLeftL,
    faArrowCircleRightL,
    faArrowCircleLeftR,
    faArrowCircleRightR,
    faArrowToBottom,
    faPlayCircle,
    faPencilAlt,
    faMapMarkerAltR,
    faCalendarAlt,
    faFilter,
    faTachometer,
    faHandHoldingHeart,
    faBatteryBolt,
    faUsersCog,
    faTrashAlt,
    faEllipsisH,
    faPhone,
    faEnvelope,
    faFilePdf,
    faFileDownload,
    faLongArrowDown,
    faUser,
  );

  dom.watch();
}
