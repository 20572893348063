function filterFAQs($, filter) {
  $('.faq-category').each(function() {
    $(this)
      .find('.panel')
      .each(function() {
        var title = $(this)
          .find('.panel-title span')
          .text();
        var body = $(this)
          .find('.panel-body')
          .text();
        var faqText = title + body;

        if (faqText.indexOf(filter) === -1) {
          if (!$(this).hasClass('hidden')) {
            $(this).addClass('hidden');
          }
        } else if ($(this).hasClass('hidden')) {
          $(this).removeClass('hidden');
        }
      });

    if ($(this).find('.panel:not(.hidden)').length === 0) {
      $(this).addClass('hidden');
    } else if ($(this).hasClass('hidden')) {
      $(this).removeClass('hidden');
    }
  });
}

export function faqfilter($) {
  if ($('body').hasClass('template-faq_view')) {
    $('#faq-filter-input').on('input', function() {
      $('.entries').addClass('filtering');

      var filter = $(this)[0].value;
      filterFAQs($, filter);

      $('.entries').removeClass('filtering');
    });
  }
}
